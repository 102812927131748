/** @format */

@media only screen and (min-width: 620px) {
	.u-row {
		width: 600px !important;
	}
	.u-row .u-col {
		vertical-align: top;
	}

	.u-row .u-col-33p33 {
		width: 199.98px !important;
	}

	.u-row .u-col-100 {
		width: 600px !important;
	}
}

@media (max-width: 620px) {
	.u-row-container {
		max-width: 100% !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.u-row .u-col {
		min-width: 320px !important;
		max-width: 100% !important;
		display: block !important;
	}
	.u-row {
		width: 100% !important;
	}
	.u-col {
		width: 100% !important;
	}
	.u-col > div {
		margin: 0 auto;
	}
}
body {
	margin: 0;
	padding: 0;
}

table,
tr,
td {
	vertical-align: top;
	border-collapse: collapse;
}

p {
	margin: 0;
}

.ie-container table,
.mso-container table {
	table-layout: fixed;
}

* {
	line-height: inherit;
}

a[x-apple-data-detectors="true"] {
	color: inherit !important;
	text-decoration: none !important;
}

table,
td {
	color: #000000;
}
#u_body a {
	color: #0000ee;
	text-decoration: underline;
}
@media (max-width: 480px) {
	#u_row_1 .v-row-background-image--inner {
		background-position: center top !important;
		background-repeat: no-repeat !important;
	}
	#u_row_1 .v-row-background-image--outer {
		background-position: center top !important;
		background-repeat: no-repeat !important;
	}
	#u_row_1.v-row-background-image--outer {
		background-position: center top !important;
		background-repeat: no-repeat !important;
	}
	#u_content_image_1 .v-container-padding-padding {
		padding: 60px 10px 0px !important;
	}
	#u_content_image_1 .v-src-width {
		width: auto !important;
	}
	#u_content_image_1 .v-src-max-width {
		max-width: 82% !important;
	}
	#u_content_heading_1 .v-container-padding-padding {
		padding: 5px 10px 30px !important;
	}
	#u_content_heading_1 .v-font-size {
		font-size: 35px !important;
	}
	#u_content_image_2 .v-container-padding-padding {
		padding: 10px 10px 60px !important;
	}
	#u_content_image_2 .v-src-width {
		width: auto !important;
	}
	#u_content_image_2 .v-src-max-width {
		max-width: 87% !important;
	}
	#u_content_heading_2 .v-container-padding-padding {
		padding: 60px 10px 0px !important;
	}
	#u_content_heading_2 .v-font-size {
		font-size: 37px !important;
	}
	#u_content_heading_2 .v-color {
		color: #cc1211 !important;
	}
	#u_content_text_2 .v-container-padding-padding {
		padding: 10px !important;
	}
	#u_content_button_3 .v-container-padding-padding {
		padding: 15px 10px 40px !important;
	}
	#u_content_button_3 .v-size-width {
		width: 65% !important;
	}
	#u_content_text_1 .v-container-padding-padding {
		padding: 30px 10px 0px !important;
	}
	#u_content_image_7 .v-container-padding-padding {
		padding: 30px 10px 0px !important;
	}
	#u_content_image_7 .v-src-width {
		width: auto !important;
	}
	#u_content_image_7 .v-src-max-width {
		max-width: 33% !important;
	}
	#u_content_image_8 .v-container-padding-padding {
		padding: 30px 10px !important;
	}
	#u_content_image_8 .v-src-width {
		width: auto !important;
	}
	#u_content_image_8 .v-src-max-width {
		max-width: 40% !important;
	}
	#u_content_image_6 .v-container-padding-padding {
		padding: 0px 10px 30px !important;
	}
	#u_content_image_6 .v-src-width {
		width: auto !important;
	}
	#u_content_image_6 .v-src-max-width {
		max-width: 40% !important;
	}
	#u_content_social_1 .v-container-padding-padding {
		padding: 40px 10px 10px !important;
	}
	#u_content_text_3 .v-container-padding-padding {
		padding: 10px 10px 120px !important;
	}
}
