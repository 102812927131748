/** @format */

.logo {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.social {
	width: 48px;
	height: 48px;
	color: #0e2027;
	display: flex;
	border-radius: 10px;
	align-items: center;
	justify-content: center;
	/* background-color: ; */
	background-color: rgba(241, 206, 137);
	/* opacity: 0.8; */
}

.social svg {
	width: 32px;
	height: 32px;
	object-fit: contain;
}
.privacy.tiny {
	text-align: center;
	justify-content: center;
	color: rgba(241, 206, 137);
}
.tiny svg {
	width: 12px;
	height: 12px;
	margin-right: 4px;

	object-fit: contain;
}

.privacy {
	width: 100%;
	display: inline-flex;
	color: rgba(241, 206, 137);
	text-decoration: none;
	text-align: right;
	align-items: center;
	justify-content: center;
}

.wrapper-pp {
	padding: 48px 24px;
	font-family: "Noto Sans";
	line-height: 1.4;
}

@media only screen and (min-width: 1024px) {
	.privacy.tiny {
		text-align: left;
		justify-content: flex-start;
	}
	.privacy {
		text-align: right;
		justify-content: flex-end;
	}
}

.js-header {
	background-color: transparent;
	transition: all 0.3s ease-in-out;
	position: relative;
	isolation: isolate;
	padding: 8px 0;
}
.js-header:before {
	content: "";
	background: linear-gradient(
		180deg,
		rgba(7, 31, 37, 1) 40%,
		rgba(7, 31, 37, 0)
	);

	inset: -12px 0 0;
	transition: all 0.3s ease-in-out;
	position: absolute;
	z-index: -1;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	width: 100vw;
	height: calc(100% + 12px);
	pointer-events: none;
}
.js-header.fade-in:before {
	opacity: 1;
}
.js-header.fade-in::after {
	opacity: 1;
	transform: scale(1, 1);
}

.js-header::after {
	content: "";
	border-bottom: thin solid #058686;
	inset: 0;
	transform-origin: center;
	transform: scale(0, 1);
	transition: all 0.3s ease-in-out;
	position: absolute;
	z-index: -1;
	pointer-events: none;
}
.js-header.fade-in .logo {
	transform: scale(0.8);
}
.logo {
	transform: scale(1);
	transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1024px) {
	.js-header:before {
		content: "";
		background: rgba(7, 31, 37, 0.79);
		border-radius: 16px;
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
		border: 1px solid rgba(7, 31, 37, 0.65);
	}
}
