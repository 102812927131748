/**
 * /* forms
 *
 * @format
 */
/* Login form */
.row {
	display: flex;
	/* align-items: center; */
	width: 100%;
	/* min-height: 80vh; */
	/* height: 90vh; */
	isolation: isolate;
	position: relative;
}

.row.auth-row {
	display: grid;
	grid-template-columns: 54% 46%;
	grid-template-areas:
		"girlande form"
		"logo form"
		"fireworks form"
		"cake form";
	gap: 0px 48px;
	align-items: flex-start;
	overflow: hidden;
}
svg.girlande-image {
	grid-area: girlande;
	align-self: flex-start;
}
div[className*="makeStyles"] {
	grid-area: logo;
	align-self: flex-start;
}
.fireworks-wrapper {
	grid-area: fireworks;
	max-height: 200px;

	position: relative;
	height: 200px;
}
path.cake-path {
	stroke-dasharray: 10000;
	stroke-dashoffset: 4000;
	animation: reveal 3s linear forwards;
}
.form {
	grid-area: form;
	align-self: center;
}

svg.cake-image {
	align-self: flex-end;
	grid-area: cake;
	max-height: 220px;
	padding-bottom: 40px;
}

.row.paper-row {
	height: auto;
}
.row.paper-row .row-col {
	flex-direction: column;
}

.col-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 46%;
	height: 100%;
}

.form {
	width: 80%;
	padding-block: 24px 48px;
}
.col-image {
	flex: 0 1 auto;
	width: 54%;
	justify-content: space-between;

	display: flex;
	flex-direction: column;
	background-color: #192a2f;
}
.col-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: aliceblue;
	-webkit-box-shadow: 0 0 0 30px #1f353b inset !important;
}

.input-wishlist {
	width: 100%;

	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	align-items: center;
}
.input-file {
	margin: 16px 0;
	width: 100%;

	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	align-items: center;
}
.input-file span.MuiTypography-p {
	width: auto;
	/* max-width: 100px; */
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
	overflow: hidden;
}
.input-file.photo {
	display: flex;
}
.input-file input[type="file" i]::-webkit-file-upload-button {
	padding: 0;
	cursor: pointer;
	user-select: none;
	vertical-align: middle;
	text-decoration: none;
	color: inherit;
	font-family: Noto Sans, Philosopher;
	font-weight: 500;
	font-size: 0.9375rem;
	line-height: 1.75;
	text-transform: capitalize;
	min-width: 64px;
	padding: 8px 22px;
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.87);
	background-color: var(--secondary-main) !important;
	border: 0px solid var(--secondary-main);
	color: #061f25;
	padding: 8px 24px;
	border-radius: 90px;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: -0.2px;
	text-transform: none;
	box-shadow: none;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text:not(.no-effect, .animated-share) {
	text-transform: capitalize;
}
a.link:-webkit-any-link {
	color: white;
	cursor: pointer;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 3px;
	text-decoration-color: #f1ce89 !important;
	text-decoration-skip-ink: none;
	word-break: break-all;
}

a.link.-rounded {
	background: linear-gradient(97deg, #058686 0%, #175565 10%, #058686 98%);
	width: 52px;
	height: 52px;
	border-radius: 50%;
	position: absolute;
	top: -40px;
	right: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	background-position: 1% 50%;
	background-size: 200% 200%;
	transform: scale(1);
	transition: all 0.3s ease;
}
a.link.-rounded:hover {
	transform: scale(1.4);
	background-position: 100% 100%;
}
@media only screen and (min-width: 1140px) {
	.fireworks-wrapper {
		transform: scale(1.25);
	}
	.row.auth-row:before {
		content: "";
		left: 0;
		inset-block: auto;
		margin: auto 0;
		width: 54%;
		background-color: #192a2f;
		position: absolute;
		z-index: -1;
		height: 100%;
		pointer-events: none;
	}
}

svg.fireworks-image.top {
	position: absolute;
	inset: 0;
}
svg.fireworks-image {
	width: 100%;
	height: 100%;
}
svg.fireworks-image.top path {
	fill: var(--color, #e1cc7a);
	opacity: 1;
	/* fill: #f2e9cb; */
}

svg.fireworks-image.top .item1 {
	--color: #a0d0d0;
	--dur: 1s;
	--delay: 0.04s;
}
svg.fireworks-image.top .item3 {
	--color: #f2e9cb;
	--dur: 1.4s;
	--delay: 0.02s;
}

svg.fireworks-image.top .item2 {
	--color: #f1e4a5;
	--dur: 1.2s;
	--delay: 0s;
}

svg.fireworks-image.top .item4 {
	--color: #a0d0d0;
	--dur: 0.8s;
	--delay: 0.08s;
}

svg.fireworks-image.top .item5 {
	--color: #f2e9cb;
	--dur: 1.3s;
	--delay: 0.03s;
}
svg.fireworks-image.top g.item0 {
	clip-path: circle(0px at 50% 50%);
	transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
	/* transition: all var(--dur, 1s) cubic-bezier(0.68, -0.55, 0.27, 1.55); */
}
svg.fireworks-image.top.active g.item0 {
	clip-path: circle(180px at 50% 50%);
	transition: all var(--dur, 1s) cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
@keyframes reveal {
	100% {
		stroke-dashoffset: 0;
	}
}
.girlande-image,
svg.cake-image {
	width: 100%;
}

.MuiFormGroup-root.helper-grid.--offset.css-dmmspl-MuiFormGroup-root {
	display: inline-flex;
}

@media only screen and (min-width: 1024px) {
	.access-form {
		display: grid;
		grid-template-columns: 290px 230px;
		align-items: flex-end;
	}
	.row-wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		/* gap: 8px; */
	}
	a.link.-rounded {
		width: 40px;
		top: -66px;
		height: 40px;
	}
	.row {
		height: 84vh;
	}
}

@media only screen and (max-width: 1024px) {
	svg.fireworks-image.-bot {
		opacity: 0.6;
	}
	.fireworks-wrapper {
		position: absolute;
		inset-inline: 0;
		margin: auto;
		pointer-events: none;
	}
	svg.girlande-image {
		transform: translateY(-20px);
	}
	.row {
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		height: auto;
	}
	.col-image,
	.col-form {
		width: 100%;
	}
	.col-image img {
		height: 280px;
	}
	.form {
		width: calc(100% - 32px);
		padding: 48px 16px;
	}
	.row.auth-row {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas:
			"girlande "
			"logo "
			"fireworks"
			" form"
			"cake ";
		gap: 0px 48px;
		align-items: flex-start;
	}
}
