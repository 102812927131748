/**
 * /* Avatar
 *
 * @format
 */

.avatar {
	position: relative;
	isolation: isolate;
}
.avatar {
	/* --before-bg: var(--primary-main); */
	--before-bg: #a0d0d0;
	/* --after-bg: var(--secondary-main); */
	--after-bg: #f7e2ba;
	--avatar-size: 40px;
	--opacity: 1;
	margin-inline: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar.--left-zero {
	margin-left: 0;
	--after-bg: #f7e2ba;
	--before-bg: #a0d0d0;
}
.avatar.--lg {
	--before-bg: #f1ce89;
	--avatar-size: 70px;
	--opacity: 0;
	margin-inline: 0;
	width: calc(var(--avatar-size) + 0px);
	height: calc(var(--avatar-size) + 0px);
	transform: translate(4px, 4px);
}
.avatar.contact-image {
	--before-bg: #a0d0d0;
	--avatar-size: 100%;
	--after-bg: #f7e2ba;
}
.contact-image-image {
	max-height: clamp(300px, 39vh, 416px);
	object-fit: cover;
	mix-blend-mode: luminosity;
	object-position: 50% 94%;
	clip-path: inset(0px round 16px);
}
.avatar.contact-image:before,
.avatar.contact-image:after {
	border-radius: 16px;
}
.avatar.contact-image::after {
	transform: rotate(-4deg);
}
.avatar.--xl {
	--before-bg: #a0d0d0;
	--after-bg: #f7e2ba;
	/* --before-bg: #39a7a7; */
	--avatar-size: 90px;
	--opacity: 1;
	margin-inline: 0;
	width: calc(var(--avatar-size) + 0px);
	height: calc(var(--avatar-size) + 0px);
	transform: translate(0px, 4px);
}
.contact-image-bg {
	width: 100%;
	clip-path: inset(0px round 16px);
	/* background: var(--neutral-main); */
	background-color: #f7e2ba;
}
.avatar:before,
.avatar:after {
	content: "";
	position: absolute;
	inset: -4px;
	width: calc(var(--avatar-size) + 8px);
	height: calc(var(--avatar-size) + 8px);
	border-radius: 4px;
	transform: rotate(6deg);
	margin: auto;
	background-color: var(--before-bg);
	z-index: -1;
	opacity: var(--opacity, 1);
	transform-origin: center;
}

.avatar:after {
	inset: -3px;
	width: calc(var(--avatar-size) + 6px);
	height: calc(var(--avatar-size) + 6px);
	transform: rotate(-6deg);
	background-color: var(--after-bg);
}

@media only screen and (max-width: 1024px) {
	.avatar {
		--avatar-size: 40px;
		margin-inline: 0px;
	}
	.avatar.--xl {
		--avatar-size: 70px;
	}
	.contact-image-image {
		max-height: 216px;
	}
	.friend-wrapper .avatar.--lg {
		--avatar-size: 56px;
		transform: translateY(-10px);
	}
}
.friend-wrapper h4 {
	word-break: break-word !important;
}
