/** @format */

.wishlist {
	position: relative;
	width: 100%;
	min-height: 240px;
}
.wishlistContent {
	position: absolute;
	bottom: 0px;
	width: 100%;
	box-sizing: border-box;
	padding: 16px;
}
.wishlist-image {
	height: 100%;
	height: 240px;
}
.wishlist a.list-link {
	display: flex;
}
.overlay-wrapper {
	position: relative;
}
.overlay-wrapper .overlay-cardMedia {
	right: 16px;
	top: 16px;
	left: auto;
}

.wishlistBadge {
	/* background-color: var(--secondary-color03); */
	color: var(--neutral-main);
	padding: 5px 5px 5px 10px;
	font-weight: 600;
	font-size: 12px;

	gap: 4px;
	border-radius: 90px;
	position: relative;
	isolation: isolate;
	display: inline-grid;
	grid-template-columns: 1fr 26px;
	align-items: center;
	justify-content: space-between;
	color: #ffe4a9;
	font-family: var(--font-primary);
	background: var(--background);
}

.wishlistBadge-text {
	padding-left: 8px;
	font-family: var(--font-primary);
	font-weight: 400;
	letter-spacing: 0.4px;
	text-transform: none;
	white-space: nowrap;
}
.access-list {
	font-size: 0;
	width: 20px;
	height: 18px;
	background-color: #213354;
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 11C18.7 11 19.37 11.1 20 11.29V10C20 8.9 19.1 8 18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H12.26C11.47 20.87 11 19.49 11 18C11 14.13 14.13 11 18 11ZM8.9 6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8H8.9V6Z' fill='%23E0D2B3'/%3E%3Cpath d='M18 13C15.24 13 13 15.24 13 18C13 20.76 15.24 23 18 23C20.76 23 23 20.76 23 18C23 15.24 20.76 13 18 13ZM18 15C18.83 15 19.5 15.67 19.5 16.5C19.5 17.33 18.83 18 18 18C17.17 18 16.5 17.33 16.5 16.5C16.5 15.67 17.17 15 18 15ZM18 21C16.97 21 16.06 20.48 15.52 19.68C16.25 19.26 17.09 19 18 19C18.91 19 19.75 19.26 20.48 19.68C19.94 20.48 19.03 21 18 21Z' fill='%23E0D2B3'/%3E%3C/svg%3E%0A");
	background-size: 20px auto;
	background-repeat: no-repeat;
	background-position: 6px 50%;
	padding: 5px 10px 5px 20px;
	font-size: 10px;
	border-radius: 90px;
	display: flex;
	border: thin solid var(--secondary-color03);
	justify-content: flex-end;
	margin-right: 9px;
}
.wishlistBadge-lvl {
	width: 26px;
	height: 26px;
	background: #c6ac79;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #131823;
	flex-shrink: 0;
	font-size: 16px;
}

.lvl3 span.wishlistBadge-lvl,
.lvl4 span.wishlistBadge-lvl {
	background: #f7e2ba;
}
/* Rectangle 6 */

.lvl1 {
	--background: #213354;
}
.lvl2 {
	--background: #1e2f4c;
}
.lvl3 {
	--background: #17243b;
}

.lvl4 {
	--background: linear-gradient(100.1deg, #10131a 0%, #213454 87.43%);
}

.lvl5 {
	--background: #3c2031;
}

.lvl6 {
	--background: #5a3148;
	/* color: #fff; */
}
.lvl6 .wishlistBadge-lvl svg {
	font-size: 16px;
}
.lvlWish {
	--background: #0c2f38;
	/* --background: #1e5454; */
}

.overlay .wishlistBadge.lvlWish {
	position: relative;
}
.wishlistBadge.lvlWish {
	position: absolute;
	z-index: 2;
	top: 16px;
	left: 16px;
	grid-template-columns: 20px 1fr 26px;
}
.wishlistBadge-icon {
	width: 20px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.wishlistBadge-icon svg {
	width: 20px;
	height: 18px;
	object-fit: contain;
	color: #c6ac79;
}
.wishlistBadge.lvlWish .wishlistBadge-text {
	padding-left: 0;
}

.wishlistBadge:before {
	--offset: 2px;
	content: "";
	width: calc(100% - var(--offset) * 2);
	height: calc(100% - var(--offset) * 2);
	inset: 0;
	position: absolute;
	border-radius: 90px;
	margin: auto;
	z-index: 2;
	border: thin solid var(--secondary-color03);
}
.wishlines:last-child .wishline {
	border-bottom: none;
	margin-bottom: 48px;
}
.wishline {
	display: grid !important;
	grid-template-columns: 90px clamp(180px, 13vw, 260px) 140px 116px 150px 180px 1fr 1fr;
	grid-template-areas: "avatar title lvl rating time link share more";
	align-items: center;
	gap: clamp(10px, 1.3vw, 24px);
	padding: 16px 0px 16px;
	border-bottom: thin solid rgba(255, 255, 255, 0.2);
	isolation: isolate;
	position: relative;
}
.wishline:before {
	content: "";
	background: linear-gradient(
		90deg,
		transparent,
		var(--gradient_spot1_color),
		transparent 90%
	);
	inset: 0;
	z-index: -1;
	position: absolute;
	transition: all 0.3s ease;
	opacity: 0;
}
.wishline:hover:before {
	opacity: 1;
}
.wishline:hover .wishline-avatar {
	transform: scale(1.1) rotate(-10deg);
	transition: all 0.3s ease;
}
.wishline-avatar {
	grid-area: avatar;
	transform: scale(1) rotate(3deg);
	transition: all 0.3s ease;
}

.avatar.wishline-avatar.--xl {
	background-color: #fff;
	border-radius: 8px;
}
.avatar.--left-zero:before,
.avatar.--left-zero:after,
.avatar.wishline-avatar.--xl:before,
.avatar.wishline-avatar.--xl::after {
	transform: rotate(0deg) scale(0.9);
	border-radius: 8px;
	transition: all 0.3s ease;
}
.avatar.--left-zero:before,
.avatar.--left-zero:after {
	transform: rotate(0deg) scale(0.7);
}
.popup-list li:hover .avatar.--left-zero:before,
.wishline:hover .avatar.wishline-avatar.--xl:before {
	transform: rotate(-6deg) scale(1);
}
.popup-list li:hover .avatar.--left-zero:after,
.wishline:hover .avatar.wishline-avatar.--xl:after {
	transform: rotate(6deg) scale(1);
}
.wishline-title {
	width: 100%;
	grid-area: title;
}
.wishline-avatar:hover,
.wishline-title:hover {
	cursor: pointer;
}
.wishline-badge {
	grid-area: lvl;
}
.wishline-text {
	max-width: 40ch;
}
.wishline-time {
	grid-area: time;
}
.wishline-link {
	grid-area: link;
}
.wishline-delete {
	grid-area: share;
}
.wishline-action {
	grid-area: more;
}

.wishline-media {
	clip-path: inset(0px round 8px);
}
.wishline-rating {
	grid-area: rating;
}
a.link.link-emerald {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	color: inherit;
	font-family: "Noto Sans";
	line-height: 1.75;
	min-width: 64px;
	text-decoration: none;
	border-radius: 4px;
	background-color: #058686;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border: thin solid #f1ce89;
	color: #fff;
	border-radius: 90px;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: -0.2px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
	padding: 8px 22px;
	gap: 10px;
	background: linear-gradient(
		102.03deg,
		#043c3c 4.19%,
		#089191 52%,
		#043c3c 100%
	);
	background-position: 1% 50%;
	background-size: 200% 200%;
	text-decoration: none !important;
}
a.link.link-emerald:hover {
	background-position: 100% 50%;
}
.link-icon {
	display: inline-flex;
	align-items: center;
}
.wishline-delete .no-effect:hover {
	opacity: 0.8;
}
.wishline-delete .no-effect svg {
	transform: scale(1) rotate(0deg);
	transition: all 200ms cubic-bezier(0.68, -0.55, 0.27, 1.55) 0ms;
}
.wishline-delete .no-effect:hover svg {
	transform: scale(1.2) rotate(15deg);
}

@media only screen and (max-width: 1336px) {
	.wishlistBadge-icon svg {
		width: 16px;
		height: 14px;
	}
}
@media only screen and (max-width: 1116px) {
	.wishlistBadge {
		grid-template-columns: 1fr 24px;
		gap: 0px;
	}

	.wishlistBadge-text {
		font-size: 0;
		width: 20px;
		height: 18px;
		background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 11C18.7 11 19.37 11.1 20 11.29V10C20 8.9 19.1 8 18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H12.26C11.47 20.87 11 19.49 11 18C11 14.13 14.13 11 18 11ZM8.9 6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8H8.9V6Z' fill='%23E0D2B3'/%3E%3Cpath d='M18 13C15.24 13 13 15.24 13 18C13 20.76 15.24 23 18 23C20.76 23 23 20.76 23 18C23 15.24 20.76 13 18 13ZM18 15C18.83 15 19.5 15.67 19.5 16.5C19.5 17.33 18.83 18 18 18C17.17 18 16.5 17.33 16.5 16.5C16.5 15.67 17.17 15 18 15ZM18 21C16.97 21 16.06 20.48 15.52 19.68C16.25 19.26 17.09 19 18 19C18.91 19 19.75 19.26 20.48 19.68C19.94 20.48 19.03 21 18 21Z' fill='%23E0D2B3'/%3E%3C/svg%3E%0A");
		background-size: 20px auto;
		background-repeat: no-repeat;
		background-position: 50%;
	}
	.wishlistBadge.lvlWish .wishlistBadge-text {
		display: none;
	}
	.wishlistBadge.lvlWish {
		grid-template-columns: 20px 1fr;
	}
	.wishlistBadge-lvl {
		width: 24px;
		height: 24px;
	}
	.wishlistBadge.lvlWish {
		top: 8px;
		left: 8px;
	}
	.overlay-wrapper .wishlistBadge.lvlWish {
		top: 16px;
		left: 16px;
	}
	.overlay-wrapper .overlay-cardMedia {
		right: 8px;
		top: 8px;
	}
}
.rating-wrap span {
	font-size: 20px !important;
}
.listTitle {
	color: white;
	cursor: pointer;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 3px;
	text-decoration-color: #f1ce89 !important;
	text-decoration-skip-ink: none;
	word-break: break-all;
}

.choiseText {
	color: #fff;
	font-size: 30px;
	font-style: italic;
}

.access-grid {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.grid-b,
.result-grid,
.helper-grid {
	display: flex;
	width: 100%;
	position: relative !important;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
}
.helper-grid-text {
	display: flex;
	gap: 20px;
	padding-top: 0;
	position: relative !important;
	align-items: center;
}
.access-limit {
	margin-bottom: 0px;
}
.helper-btn {
	padding: 4px !important;
}
.helper-btn.-new {
	position: absolute !important;
	top: -2px;
	right: -2px;
	margin: 0;
}

.wishlist-update {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	opacity: 0.8;
	padding: 8px 0;
}

.not-active {
	position: relative;
	isolation: isolate;
}
.not-active:hover .received {
	animation: rotating 42s linear;
	color: #f1e4a5;
}
.not-active:hover .received-wrapp {
	transform: rotate(-11deg) translate(40px, 40px) scale(1.2);
}
.not-active .wishline {
	opacity: 0.5;
	z-index: -1;
	pointer-events: none;
}
.not-active:after {
	content: "";
	background-image: linear-gradient(
		180deg,
		rgba(6, 31, 37, 0),
		rgba(6, 31, 37, 0.2)
	);
	inset: 0;
	position: absolute;
	opacity: 1;
	z-index: 1;
}
.received-wrapp {
	position: absolute;
	inset: 0;
	width: 90px;
	object-fit: contain;
	height: 90px;
	z-index: 2;
	display: flex;
	align-items: center;
	transform: rotate(-11deg) translate(40px, 40px) scale(1);
	justify-content: center;
	transition: all 0.3s ease;
}
.received {
	width: 100%;
	object-fit: contain;
	height: 100%;
	background: rgba(6, 31, 37, 0.98);
	border-radius: 50%;
	/* color: powderblue; */
	transition: color 0.3s ease;
	color: #dbffff;
	/* color: #a0d0d0; */
}

@keyframes rotating {
	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 1024px) {
	.wishline-badge {
		margin-right: auto;
	}
	.wishline-permission {
		display: flex;
	}
	.received-wrapp {
		left: auto;
		right: 60px;
	}
	.helper-grid {
		flex-wrap: wrap;
		gap: 0 20px;
	}
	.grid-b,
	.result-grid {
		flex-wrap: wrap;
		gap: 10px;
		margin-block: 10px 20px;
	}
	.wishline {
		display: grid !important;
		grid-template-columns: 100px 80px 1fr;
		grid-template-areas: " avatar lvl rating " "avatar title title" "avatar time time" "share share link" "more more link";
		align-items: center;
		gap: 8px 8px;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 16px 0px 16px;
		border-bottom: thin solid rgba(255, 255, 255, 0.2);
	}
	.wishline-link a.link-emerald.link {
		font-size: 14px;
	}
	.helper-grid.--offset {
		align-items: flex-start;
	}

	.more-actions .MuiPopper-root button {
		padding: 10px 20px;
		min-width: 180px;
	}
	.wishlistBadge {
		padding: 5px 5px 5px 10px;
		font-size: 10px;
		gap: 4px;
		border-radius: 90px;
		display: inline-grid;
		grid-template-columns: 1fr 20px;
	}
	.wishlistBadge-text {
		padding-left: 4px;
	}
	.lvl6 .wishlistBadge-lvl,
	.lvl1 .wishlistBadge-lvl,
	.lvl2 .wishlistBadge-lvl,
	.lvl3 .wishlistBadge-lvl,
	.lvl4 .wishlistBadge-lvl,
	.lvl5 .wishlistBadge-lvl {
		background-color: #e1d1b3;
	}
	.wishlistBadge-lvl {
		width: 20px;
		height: 20px;

		font-size: 14px;
	}
	.rating-wrap span {
		font-size: 20px !important;
	}
	.rating-wrap {
		width: auto;
		padding: 3px 10px 1px;
		margin-inline: 0 auto;
	}
}

.popup-list {
	/* max-height: 60vh; */
	/* overflow-y: auto; */
	/* padding: 0 0px 20px !important; */
}
.popup-list-wish {
	border-bottom: thin solid rgba(255, 255, 255, 0.15);
}
.MuiListSubheader-root.MuiListSubheader-gutters.MuiListSubheader-sticky {
	top: -10px;
}
.popup-list
	li.MuiListSubheader-root.MuiListSubheader-gutters.MuiListSubheader-sticky.css-1bs1ca0-MuiListSubheader-root {
	top: -10px !important;
}
.popup-list-wish:last-child {
	border-bottom: none;
}
.popup-list-wish * {
	font-family: var(--font-primary) !important;
	text-transform: capitalize;
}
@media only screen and (min-width: 1024px) {
	.sticky-desktop {
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 60px;
	}
	.--offset .helper-btn {
		right: -20px;
	}
	.helper-grid-text {
		width: 480px;
	}
	.access-grid.-wishlist {
		min-width: 900px;
	}
}

.icons-rating {
	position: relative;
	z-index: 9999;
}
