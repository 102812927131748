/**
 * /* Countdown banner
 *
 * @format
 */

.banner-wrapper {
	position: relative;
}
.banner-bg {
	position: absolute;
	inset: 0;
	margin: auto;
	z-index: -1;
}
.banner-bg.--small {
	border-radius: 20px;
	overflow: hidden;
}
.banner-bg:before {
	content: "";
	background: linear-gradient(
		0deg,
		var(--neutral-main) 5%,
		/* transparent, */ rgba(6, 31, 37, 0.36),
		var(--neutral-main) 95%
	);
	inset: 0;
	position: absolute;
}
.banner-bg.--small:before {
	background: var(--neutral-main);
	opacity: 0.6;
	/* mix-blend-mode: color; */
}

.banner-image {
	object-fit: cover;
	object-position: 50% 67%;
	width: 100%;
	height: 100%;
}
