/**
 * /* Main decor
 *
 * @format
 */

.decor {
	position: relative;
	isolation: isolate;
}
.decor path {
	stroke-width: 10px;
	stroke-dasharray: 2000;
	stroke-dashoffset: 2000;
	transition: all 0.4s ease-in-out;
}

.decor svg {
	position: absolute;
	inset: 0;
	width: 110%;
	height: 100%;
	object-fit: contain;
	z-index: -1;
	margin: auto;
	object-position: center;
}
.decor svg.circleSvg {
	stroke-width: 5px;
	width: 140%;
	left: -10%;
	min-height: 90px;
}
.circleSvg path {
	stroke-width: 5px;
}
.decor svg.wavySvg,
.decor svg.undelineSvg {
	bottom: -20px;
	object-position: bottom;
	top: auto;
}
.decor {
	display: inline-flex;
}

.loading-svg path {
	--stroke-value: 2000;
	stroke-dashoffset: var(--stroke-value);
	stroke-dasharray: var(--stroke-value);
	animation: 2s linear infinite alternate animateDraw;
}

@keyframes animateDraw {
	from {
		stroke-dashoffset: var(--stroke-value);
	}
	to {
		stroke-dashoffset: 0;
	}
}

.loading-wrapper {
	position: fixed;
	z-index: 9;
	/* background-color: rgb(23 85 101 / 60%); */
	background-color: rgb(6 31 37 / 70%);
	/* background-image: linear-gradient(
		0deg,
		rgb(6 31 37 / 20%),
		rgb(6 31 37 / 93%),
		rgb(6 31 37 / 20%)
	); */
	width: 100%;
	height: 100%;
	inset: 0;
	margin: auto;
	display: flex;
	opacity: 0;
	animation: fadeIn 0.3s linear forwards;
	align-items: center;
	justify-content: center;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@media only screen and (max-width: 1024px) {
	.loading-svg {
		transform: scale(2.5);
	}
}
