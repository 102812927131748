/** @format */
@import "./style/avatar.css";
@import "./style/banner.css";
@import "./style/base.css";
@import "./style/email.css";
@import "./style/errorPage.css";
@import "./style/header.css";
@import "./style/home.css";
@import "./style/forms.css";
@import "./style/paper.css";
@import "./style/svgDecor.css";
@import "./style/wish.css";

:root {
	--primary-main: #d1d1f5;
	--primary-color01: #071f25;
	--primary-color02: #998ab5;

	--secondary-main: #fefedf;
	--secondary-color03: #f1ce89;

	--neutral-main: #061f25;

	--neutral-color02: #8ea4a3;
	/* --neutral-color03: #b6d1d9; */
	--neutral-color03: #b3d9d9;
	--neutral-color04: #def6ef;
	--neutral-light: #ffffff;
	/* FEFEDF */
	--neutral-dark: #000000;
	--font-primary: "Noto Serif";
	--font-secondary: "Noto Sans";

	/* Gradient  */
	--gradient_bg_color: transparent;

	--gradient_spot1_color: rgba(23, 85, 101, 0.5);
	--gradient_spot1_w: 20vw;
	--gradient_spot1_h: 20vw;
	--gradient_spot1_x: -0%;
	--gradient_spot1_y: 60%;

	--gradient_spot2_color: rgba(23, 85, 101, 0.6);
	--gradient_spot2_w: 30vw;
	--gradient_spot2_h: 30vw;
	--gradient_spot2_x: 80%;
	--gradient_spot2_y: 20%;

	--gradient_spot3_color: #42ffc6;
	--gradient_spot3_w: 75vw;
	--gradient_spot3_h: 75vh;
	--gradient_spot3_x: 100%;
	--gradient_spot3_y: 100%;

	--gradient_spot4_color: #ff3b8d;
	--gradient_spot4_w: 75vw;
	--gradient_spot4_h: 75vh;
	--gradient_spot4_x: 0;
	--gradient_spot4_y: 100%;
}

@media only screen and (max-width: 1024px) {
	:root {
		--gradient_spot1_w: 60vw;
		--gradient_spot1_h: 60vw;
		--gradient_spot1_color: rgba(23, 85, 101, 0.6);
		--gradient_spot2_color: rgba(23, 85, 101, 0.36);
		--gradient_spot2_y: 30%;
		--gradient_spot2_w: 30vw;
		--gradient_spot2_h: 30vw;
	}
	.tinyTab {
		font-size: 10px !important;
	}
}
/* Icons  */
.icon-grid {
	display: inline-flex;
	gap: 0 8px;
	align-items: flex-start;
}
.cursor-pointer {
	cursor: pointer;
}
.underline {
	text-decoration: underline;
}
.icon-grid.--center {
	align-items: center;
}
.icon-grid p {
	text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.68);
	line-height: 1.2;
}
.icon-grid.-no-shadow p {
	text-shadow: none;
}
/* Colors */
.accent {
	color: var(--secondary-color03);
}
.accent2 {
	color: #a0d0d0;
}
/* Effects */
.logo:hover {
	cursor: pointer;
}

.wishboard .banner-content h4,
.wishboard .banner-content span {
	font-size: clamp(20px, 2vw, 40px) !important;
}
@media only screen and (min-width: 1024px) {
	.tinyTab {
		/* display: none; */
		display: flex !important;
		flex-direction: row !important;
		align-items: center !important;
		gap: 4px !important;
	}
	.tinyTab svg {
		margin-bottom: 0 !important;
		width: 18px !important;
	}
	.language-wrapper {
		margin-right: 20px;
	}
	.language {
		margin-inline: 20px;
	}
}

.containerH {
	min-height: 100vh;
}
