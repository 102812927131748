/** @format */

.dates-grid {
	--gap: 50px;
	display: flex;
	justify-content: center;
	gap: var(--gap, 50px);
}

.wish-manager {
	margin-bottom: 8px;
	display: flex !important;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;
}
.dates-item h2 {
	font-family: var(--font-secondary) !important;
}
.--small .dates-grid {
	--gap: 10px;
}

.friend-page .friend-wrapper h2 {
	/* max-width: 300px; */
	max-width: 100%;
	word-break: break-all;
}

.overlay-cardMedia {
	position: absolute;
	inset: 0;
	background: linear-gradient(180deg, rgba(1, 14, 17, 0.5), transparent 40%);
	pointer-events: none;
}
.overlay-cardMedia.transparent {
	background: transparent;
}
.overlay-cardMedia.--full {
	background: linear-gradient(
		180deg,
		rgba(1, 14, 17, 0.3),
		rgba(1, 14, 17, 0.7) 100%
	);
}
.--friend .event {
	margin-bottom: 16px;
}
.event {
	/* background-color: #dbffff; */
	/* color: var(--neutral-main); */
	padding: 4px 16px;
	margin-block: 16px 8px !important;
	border-radius: 90px;
	display: inline-flex;
	background-color: #1d5454;
	background: linear-gradient(-45deg, #1d5454, #0b2121);
	font-family: var(--font-primary) !important;
	font-weight: 400;
	letter-spacing: 0.4px;
	white-space: nowrap;
	/* border: thin solid #dbffff; */
}

.rating-wrap {
	background-color: var(--primary-color01);
	padding: 6px 12px 4px;
	border-radius: 90px;
	border: thin solid #faead3;
}

.new-title {
	display: -webkit-box;
	-webkit-line-clamp: 2 !important;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.alttext {
	color: #fff !important;
	opacity: 0.8 !important;
}

.alttext2 {
	color: #dbffff !important;
}

.date-wrapper {
	padding: 0px 16px 8px;
}
.date-wrapper
	svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
	color: #dbffff !important;
	font-size: 16px;
}

.loginBtn {
	font-size: 1.4993rem !important;
	text-transform: none !important;
	opacity: 0.6;
	font-family: var(--font-primary) !important;
}
.loginBtn:hover {
	opacity: 1;
}
.form-title {
	align-items: center;
	gap: 2px;
	padding-inline: 16px;
	justify-content: center;
	transition: all 0.4s ease;
}
.form-h5 {
	padding: 16px 24px;
	background-image: url("data:image/svg+xml,%3Csvg width='162' height='95' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M161 47.5c0 12.616-8.727 24.22-23.23 32.725C123.29 88.716 103.221 94 81 94c-22.221 0-42.29-5.284-56.77-13.775C9.727 71.72 1 60.116 1 47.5s8.727-24.22 23.23-32.725C38.71 6.284 58.78 1 81 1c22.221 0 42.29 5.284 56.77 13.775C152.273 23.28 161 34.884 161 47.5Z' stroke='%239AD6D4' stroke-width='2'/%3E%3C/svg%3E");
	background-size: 100% 100%;
	background-position: 50%;
	background-repeat: no-repeat;
}

.headerSearch {
	display: grid;
	grid-template-columns: 240px 50px;
	justify-content: center;
	align-items: flex-end;
	padding-right: 10px;
	position: relative;
}
.input-focus {
	clip-path: inset(0px 0% 0px 100% round 0px);
	overflow: hidden;
	transition: all 0.3s ease;
}
.headerSearch.is-active .input-focus,
.headerSearch:hover .input-focus {
	clip-path: inset(0px 0% 0px 0px round 0px);
}
.clear-btn {
	/* position: absolute; */
	opacity: 0;
	right: 0;
	top: 0px;
	display: none;
}
.headerSearch:focus-within:hover .clear-btn {
	opacity: 1;
}
.headerSearch .searchButton {
	border: thin solid #f1ce89 !important;
	color: #fff !important;

	background: linear-gradient(
		102.03deg,
		#061f25 4.19%,
		#061f25 50%,
		#089191 100%
	) !important;
	background-size: 200% 200% !important;
	background-position: 10% 50% !important;

	transition: all 0.3s ease;
	transform: scale(0.9);
}
.styled-overflow {
	text-overflow: ellipsis;
}

.searchButton:hover {
	background-position: 100% 100% !important;
	/* clip-path: inset(0px 0px 0px 0px round 90px); */
}

@media only screen and (max-width: 1024px) {
	.headerSearch {
		display: none;
	}
	.input-wishlist,
	.input-file {
		display: grid;
		grid-template-columns: 1fr 100px;
	}
	.input-file span.MuiTypography-p {
		white-space: normal;
	}
	.wish-manager {
		position: fixed !important;
		bottom: 0px;
		width: 100vw;
		margin: auto;
		inset-inline: 0;
		/* right: 20px; */
		padding: 10px 0px;
		justify-content: center;
		z-index: 10;
		display: flex;
		/* background-color: var(--gradient_spot1_color); */
		background: #06191e;
		border-top: thin solid var(--secondary-color03);
		/* background: #20353b; */
		/* background: linear-gradient(0deg, rgba(7, 31, 37) 50%, rgba(7, 31, 37, 0)); */
	}
}
