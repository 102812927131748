/** @format */

html {
	/* overflow-y: scroll; */
	height: 100%;
	/* -webkit-overflow-scrolling: touch; */
	/* overflow-scrolling: touch; */
}

body {
	background-color: var(--neutral-main);
	/* overflow-y: visible; */
	overflow-x: hidden;
	background-size: contain;
	padding: 0;
	margin: 0;
	width: 100%;
	color: var(--neutral-light);
}
body .mainWrapper {
	padding-left: 0px;
	padding-right: 0px;
	width: 100vw;
	min-height: 100vh;
	overflow-x: clip;
	max-width: none;
	/* position: relative; */
}

.body-wrapper {
	min-height: calc(100vh - 140px);
}
.body-wrapper.--align-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

body::before {
	content: "";
	inset: 0;
	position: absolute;
	background-color: var(--gradient_bg_color);
	background-image: radial-gradient(
			var(--gradient_spot1_w) var(--gradient_spot1_h) at left
				var(--gradient_spot1_x) top var(--gradient_spot1_y),
			var(--gradient_spot1_color),
			transparent
		),
		radial-gradient(
			var(--gradient_spot2_w) var(--gradient_spot2_h) at left
				var(--gradient_spot2_x) top var(--gradient_spot2_y),
			var(--gradient_spot2_color),
			transparent
		);
	z-index: -1;
	min-height: 90vh;
	pointer-events: none;
}

.faq-contact {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	align-items: center;
	justify-content: center;
	padding-bottom: 80px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text:not(.no-effect, .animated-share) {
	background: linear-gradient(90deg, #deb47c, #dac690, #e6b472);
	background-size: 0% 1px;
	background-repeat: no-repeat;
	transition: all 0.3s ease;
	opacity: 1;
	background-position: 50% 100%;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
	background-size: 100% 1px;
}
.more-actions
	button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
	background-size: calc(100% - 32px) 1px;
	opacity: 0.8;
}
.more-actions button {
	color: #fff !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.active-page {
	background-size: 100% 1px;
	color: var(--secondary-color03) !important;
}

@media only screen and (max-width: 1024px) {
	.footer-bottom {
		/* background: linear-gradient(180deg, rgba(7, 31, 37) 50%, rgba(0, 0, 0, 1)); */
		padding-bottom: 80px;
	}
}

.language:before {
	display: none !important;
}

.language-wrapper {
	justify-content: center;
}
